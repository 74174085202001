import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Form,
  Modal,
  Badge,
  Alert,
  Accordion,
  AccordionButton,
  AccordionBody
} from 'react-bootstrap';
import {
  FaEdit,
  FaTrash,
  FaPlus,
  FaGlobeAmericas,
  FaArrowUp,
  FaTimes,
  FaCode
} from 'react-icons/fa';
import { getDomains } from '../../redux/domainSlice';
import moment from 'moment';
import {
  createDomain,
  updateDomain,
  deleteDomain,
  createAccount,
  createWebsite,
  createKeyword,
  deleteAccount,
  deleteWebsite,
  deleteKeyword,
  baseUrl,
  createCategory,
  deleteCategory,
  updateCategoryOrder
} from 'helpers/requests';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TwitterOAuth from './TwitterOAuth';

const initialPostParams = {
  title: '',
  body: '',
  seoTitle: '',
  metaDescription: '',
  image: '',
  imageFilename: '',
  imageCaption: '',
  summary: '',
  keyword: '',
  referenceArticles: '',
  categories: '',
  location: '',
  dateTime: ''
};

const Domains = () => {
  const dispatch = useDispatch();
  const domains = useSelector(state => state.domain.domains);
  const status = useSelector(state => state.domain.status);
  const { page, pages, limit } = useSelector(state => state.domain);

  const [showModal, setShowModal] = useState(false);
  const [showAvatar, setShowAvatar] = useState(true);
  const [layoutColumn, setLayoutColumn] = useState(4);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showWebsiteModal, setShowWebsiteModal] = useState(false);
  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [currentDomain, setCurrentDomain] = useState({
    categories: [],
    domainUrl: '',
    hostPage: '',
    apiEndpoint: '',
    frequencyValue: '',
    frequencyUnit: 's',
    postParamsMapping: {}
  });
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteItemType, setDeleteItemType] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [postParams, setPostParams] = useState(initialPostParams);
  const [newAccount, setNewAccount] = useState({ domainId: '', twitterId: '' });
  const [newWebsite, setNewWebsite] = useState({ domainId: '', url: '' });
  const [newKeyword, setNewKeyword] = useState({ domainId: '', phrase: '' });
  const [newCategory, setNewCategory] = useState({ domainId: '', label: '' });
  const [accountError, setAccountError] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [keywordError, setKeywordError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [accountLoading, setAccountLoading] = useState(false);
  const [websiteLoading, setWebsiteLoading] = useState(false);
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showTwitterOAuthModal, setShowTwitterOAuthModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);

  // Function to handle opening the Twitter OAuth modal
  const handleShowTwitterOAuthModal = domain => {
    setSelectedDomain(domain);
    setShowTwitterOAuthModal(true);
  };

  // Function to handle closing the Twitter OAuth modal
  const handleCloseTwitterOAuthModal = () => {
    setShowTwitterOAuthModal(false);
    setSelectedDomain(null);
  };

  // Function to handle removing Twitter permissions
  const handleRemoveTwitterPermissions = async domainId => {
    // Call your backend to remove the TwitterAuth entry for this domain
    try {
      console.log('Delete Twitter for: Domain ', domainId);
      dispatch(getDomains({ page, limit, searchTerm }));
    } catch (error) {
      console.error('Error removing Twitter permissions:', error);
    }
  };

  useEffect(() => {
    dispatch(getDomains({ page, limit, searchTerm }));
  }, [dispatch, page, limit, searchTerm]);

  const handleShowModal = (
    domain = {
      domainUrl: '',
      hostPage: '',
      apiEndpoint: '',
      frequency: '1s',
      postParamsMapping: {}
    }
  ) => {
    const { frequencyValue, frequencyUnit } = parseFrequency(domain.frequency);
    setCurrentDomain({
      ...domain,
      frequencyValue,
      frequencyUnit
    });
    setPostParams(domain.postParamsMapping || initialPostParams);
    setIsEditMode(!!domain._id);
    setShowModal(true);
  };

  const handleShowAccountModal = domainId => {
    setNewAccount({ domainId, twitterId: '' });
    setShowAccountModal(true);
  };

  const handleShowWebsiteModal = domainId => {
    setNewWebsite({ domainId, url: '' });
    setShowWebsiteModal(true);
  };

  const handleShowKeywordModal = domainId => {
    setNewKeyword({ domainId, phrase: '' });
    setShowKeywordModal(true);
  };

  const handleShowCategoryModal = domainId => {
    setNewCategory({ domainId, label: '' });
    setShowCategoryModal(true);
  };

  const handleShowEmbedModal = domain => {
    setCurrentDomain(domain);
    setShowEmbedModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentDomain({
      domainUrl: '',
      hostPage: '',
      apiEndpoint: '',
      frequencyValue: '',
      frequencyUnit: 's',
      postParamsMapping: {}
    });
    setPostParams(initialPostParams);
  };

  const handleCloseAccountModal = () => {
    setShowAccountModal(false);
    setNewAccount({ domainId: '', twitterId: '' });
    setAccountError('');
  };

  const handleCloseWebsiteModal = () => {
    setShowWebsiteModal(false);
    setNewWebsite({ domainId: '', url: '' });
    setWebsiteError('');
  };

  const handleCloseKeywordModal = () => {
    setShowKeywordModal(false);
    setNewKeyword({ domainId: '', phrase: '' });
    setKeywordError('');
  };

  const handleCloseCategoryModal = () => {
    setShowCategoryModal(false);
    setNewCategory({ domainId: '', label: '' });
    setCategoryError('');
  };

  const handleCloseEmbedModal = () => {
    setShowEmbedModal(false);
  };

  const handleSaveDomain = async () => {
    const frequency = `${currentDomain.frequencyValue}${currentDomain.frequencyUnit}`;
    const domainData = {
      ...currentDomain,
      frequency,
      postParamsMapping: postParams
    };
    if (isEditMode) {
      await updateDomain(currentDomain._id, domainData);
    } else {
      await createDomain(domainData);
    }
    dispatch(getDomains({ page, limit, searchTerm }));
    handleCloseModal();
  };

  const handleSaveAccount = async () => {
    setAccountLoading(true);
    try {
      await createAccount(newAccount);
      dispatch(getDomains({ page, limit, searchTerm }));
      handleCloseAccountModal();
    } catch (error) {
      setAccountError('Error creating account');
    } finally {
      setAccountLoading(false);
    }
  };

  const handleSaveWebsite = async () => {
    setWebsiteLoading(true);
    try {
      await createWebsite(newWebsite);
      dispatch(getDomains({ page, limit, searchTerm }));
      handleCloseWebsiteModal();
    } catch (error) {
      setWebsiteError('Error creating website');
    } finally {
      setWebsiteLoading(false);
    }
  };

  const handleSaveKeyword = async () => {
    setKeywordLoading(true);
    try {
      await createKeyword(newKeyword);
      dispatch(getDomains({ page, limit, searchTerm }));
      handleCloseKeywordModal();
    } catch (error) {
      setKeywordError('Error creating keyword');
    } finally {
      setKeywordLoading(false);
    }
  };

  const handleSaveCategory = async () => {
    setCategoryLoading(true);
    try {
      await createCategory(newCategory);
      dispatch(getDomains({ page, limit, searchTerm }));
      handleCloseCategoryModal();
    } catch (error) {
      setCategoryError('Error creating category');
    } finally {
      setCategoryLoading(false);
    }
  };

  const handleDeleteItem = (itemId, itemType) => {
    setDeleteItemId(itemId);
    setDeleteItemType(itemType);
    setShowDeleteModal(true);
  };

  const confirmDeleteItem = async () => {
    try {
      if (deleteItemType === 'account') {
        await deleteAccount(deleteItemId);
      } else if (deleteItemType === 'website') {
        await deleteWebsite(deleteItemId);
      } else if (deleteItemType === 'keyword') {
        await deleteKeyword(deleteItemId);
      } else if (deleteItemType === 'category') {
        await deleteCategory(deleteItemId);
      } else if (deleteItemType === 'domain') {
        await deleteDomain(deleteItemId);
      }
      dispatch(getDomains({ page, limit, searchTerm }));
    } catch (error) {
      console.error('Error deleting item:', error);
    } finally {
      setShowDeleteModal(false);
      setDeleteItemId(null);
      setDeleteItemType(null);
    }
  };

  const parseFrequency = frequency => {
    const value = frequency.slice(0, -1);
    const unit = frequency.slice(-1);
    return { frequencyValue: value, frequencyUnit: unit };
  };

  const formatTimeAgo = date => {
    const now = moment();
    const diff = now.diff(moment(date), 'seconds');
    if (diff < 60) return `${diff} second${diff === 1 ? '' : 's'} ago`;
    const minutes = Math.floor(diff / 60);
    if (minutes < 60) return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    const days = Math.floor(hours / 24);
    if (days < 7) return `${days} day${days === 1 ? '' : 's'} ago`;
    const weeks = Math.floor(days / 7);
    if (weeks < 4) return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    const months = Math.floor(days / 30);
    if (months < 12) return `${months} month${months === 1 ? '' : 's'} ago`;
    const years = Math.floor(months / 12);
    return `${years} year${years === 1 ? '' : 's'} ago`;
  };

  const handlePageChange = newPage => {
    dispatch(getDomains({ page: newPage, limit, searchTerm }));
  };

  const handlePageSizeChange = newLimit => {
    dispatch(getDomains({ page, limit: newLimit, searchTerm }));
  };

  const onDragEnd = async result => {
    if (!result.destination) return;
    const updatedCategories = Array.from(currentDomain.categories);
    const [reorderedItem] = updatedCategories.splice(result.source.index, 1);
    updatedCategories.splice(result.destination.index, 0, reorderedItem);

    setCurrentDomain({ ...currentDomain, categories: updatedCategories });

    // Update order in the backend
    const reorderedCategories = updatedCategories.map((cat, index) => ({
      ...cat,
      order: index
    }));
    await updateCategoryOrder(currentDomain._id, reorderedCategories);
    dispatch(getDomains({ page, limit, searchTerm }));
  };

  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col>
            <h3 className="text-primary">Domains</h3>
          </Col>
          <Col className="text-end">
            <Button variant="primary" onClick={() => handleShowModal()}>
              <FaPlus /> Create New Domain
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Form.Control
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          {status === 'loading' ? (
            <Spinner animation="border" />
          ) : (
            domains.map(domain => (
              <Col
                sm={12}
                md={6}
                lg={6}
                key={domain._id}
                className="d-flex mb-4"
              >
                <Card className="w-100">
                  <Card.Body>
                    <Badge
                      bg={domain.status === 'active' ? 'success' : 'danger'}
                    >
                      {domain.status}
                    </Badge>
                    <Card.Title className="mt-2">
                      <Row>
                        <Col sm={6}>
                          <FaGlobeAmericas />{' '}
                          {domain.domainUrl.indexOf('http') > -1
                            ? new URL(domain.domainUrl).hostname
                            : domain.domainUrl}
                        </Col>
                        <Col className="text-end">
                          <Badge bg="secondary" className="me-1">
                            {domain.userId.username}
                          </Badge>
                        </Col>
                      </Row>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        <Badge bg={domain.isPurchased ? 'success' : 'warning'}>
                          {domain.isPurchased ? 'Paid' : 'Free'}
                        </Badge>{' '}
                        <small>Added {formatTimeAgo(domain.createdAt)}</small>
                      </p>
                      <p>
                        <strong>This path will be used to send tweets</strong>
                        <br />
                        <Alert color="light">
                          <strong>{domain.hostPage}</strong>
                        </Alert>
                      </p>
                      <p>
                        <strong>
                          This is where we will post the articles{' '}
                          {domain.expiryDate
                            ? 'till ' + formatTimeAgo(domain.expiryDate)
                            : ''}
                          :
                        </strong>
                        <br />
                        <Alert color="light">
                          <strong>{domain.apiEndpoint}</strong>
                          <br />
                          <br />
                          <Accordion>
                            <AccordionButton>
                              API params mapping
                            </AccordionButton>
                            <AccordionBody>
                              <ul>
                                {Object.entries(
                                  domain.postParamsMapping || initialPostParams
                                ).map(([key, value]) => (
                                  <li key={key}>
                                    <strong>{key}:</strong> {value || key}
                                  </li>
                                ))}
                              </ul>
                            </AccordionBody>
                          </Accordion>
                        </Alert>
                      </p>
                      <Row style={{ minHeight: '50px' }}>
                        <Col sm={12}>
                          <h5>Data sources:</h5>
                        </Col>
                      </Row>
                      <Row style={{ minHeight: '50px' }}>
                        <Col sm={5}>
                          <strong>Categories:</strong>
                        </Col>
                        <Col sm={7} className="text-end">
                          {domain.categories.length < domain.categoriesLimit ? (
                            <Button
                              size="sm"
                              variant="primary"
                              onClick={() =>
                                handleShowCategoryModal(domain._id)
                              }
                            >
                              <FaPlus /> Add Category
                            </Button>
                          ) : (
                            <Button size="sm" variant="warning">
                              <FaArrowUp /> Increase Limit
                            </Button>
                          )}
                        </Col>
                        <Col sm={12} className="my-1">
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable
                              droppableId="droppable"
                              direction="vertical"
                            >
                              {provided => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {Array.from(domain.categories)
                                    .sort((a, b) => a.order - b.order)
                                    .map((category, index) => (
                                      <Draggable
                                        key={'draggable-' + index}
                                        draggableId={'draggable-' + index}
                                        index={index}
                                      >
                                        {provided => (
                                          <div
                                            key={category._id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onMouseDown={() =>
                                              setCurrentDomain(domain)
                                            }
                                          >
                                            <Badge bg="info" className="me-1">
                                              {category.label}{' '}
                                              <FaTimes
                                                className="cursor-pointer"
                                                onClick={() =>
                                                  handleDeleteItem(
                                                    category._id,
                                                    'category'
                                                  )
                                                }
                                              />
                                            </Badge>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Col>
                      </Row>
                      <hr />
                      <Row style={{ minHeight: '50px' }}>
                        <Col sm={5}>
                          <strong>Twitter Accounts:</strong>
                        </Col>
                        <Col sm={7} className="text-end">
                          {domain.accounts.length < domain.accountsLimit ? (
                            <Button
                              size="sm"
                              variant="primary"
                              onClick={() => handleShowAccountModal(domain._id)}
                            >
                              <FaPlus /> Add Account
                            </Button>
                          ) : (
                            <Button size="sm" variant="warning">
                              <FaArrowUp /> Increase Limit
                            </Button>
                          )}
                        </Col>
                        <Col sm={12} className="my-1">
                          {domain.accounts.map(account => (
                            <Badge bg="info" key={account._id} className="me-1">
                              @{account.twitterId}{' '}
                              <FaTimes
                                className="cursor-pointer"
                                onClick={() =>
                                  handleDeleteItem(account._id, 'account')
                                }
                              />
                            </Badge>
                          ))}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col sm={5}>
                          <strong>Websites:</strong>
                        </Col>
                        <Col sm={7} className="text-end">
                          {domain.websites.length < domain.websitesLimit ? (
                            <Button
                              size="sm"
                              variant="primary"
                              onClick={() => handleShowWebsiteModal(domain._id)}
                            >
                              <FaPlus /> Add Website
                            </Button>
                          ) : (
                            <Button size="sm" variant="warning">
                              <FaArrowUp /> Increase Limit
                            </Button>
                          )}
                        </Col>
                        <Col sm={12} className="my-1">
                          {domain.websites.map(website => (
                            <Badge bg="info" key={website._id} className="me-1">
                              <Link
                                to={website.url}
                                target="_blank"
                                style={{ color: 'white' }}
                              >
                                {website.url}
                              </Link>{' '}
                              <FaTimes
                                className="cursor-pointer"
                                onClick={() =>
                                  handleDeleteItem(website._id, 'website')
                                }
                              />
                            </Badge>
                          ))}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col sm={5}>
                          <strong>Keywords:</strong>
                        </Col>
                        <Col sm={7} className="text-end">
                          {domain.keywords.length < domain.keywordsLimit ? (
                            <Button
                              size="sm"
                              variant="primary"
                              onClick={() => handleShowKeywordModal(domain._id)}
                            >
                              <FaPlus /> Add Keyword
                            </Button>
                          ) : (
                            <Button size="sm" variant="warning">
                              <FaArrowUp /> Increase Limit
                            </Button>
                          )}
                        </Col>
                        <Col sm={12} className="my-1">
                          {domain.keywords.map(keyword => (
                            <Badge bg="info" key={keyword._id} className="me-1">
                              {keyword.phrase}{' '}
                              <FaTimes
                                className="cursor-pointer"
                                onClick={() =>
                                  handleDeleteItem(keyword._id, 'keyword')
                                }
                              />
                            </Badge>
                          ))}
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <strong>Frequency:</strong>{' '}
                          {`${
                            parseFrequency(domain.frequency).frequencyValue
                          } `}
                          {parseFrequency(domain.frequency).frequencyUnit ===
                            's' &&
                            (parseFrequency(domain.frequency).frequencyValue <=
                            1
                              ? 'second'
                              : 'seconds')}
                          {parseFrequency(domain.frequency).frequencyUnit ===
                            't' &&
                            (parseFrequency(domain.frequency).frequencyValue <=
                            1
                              ? 'minute'
                              : 'minutes')}
                          {parseFrequency(domain.frequency).frequencyUnit ===
                            'h' &&
                            (parseFrequency(domain.frequency).frequencyValue <=
                            1
                              ? 'hour'
                              : 'hours')}
                          {parseFrequency(domain.frequency).frequencyUnit ===
                            'd' &&
                            (parseFrequency(domain.frequency).frequencyValue <=
                            1
                              ? 'day'
                              : 'days')}
                          {parseFrequency(domain.frequency).frequencyUnit ===
                            'm' &&
                            (parseFrequency(domain.frequency).frequencyValue <=
                            1
                              ? 'month'
                              : 'months')}
                          {parseFrequency(domain.frequency).frequencyUnit ===
                            'y' &&
                            (parseFrequency(domain.frequency).frequencyValue <=
                            1
                              ? 'year'
                              : 'years')}
                        </Col>
                        <Col className="text-end">
                          <strong>Last parsed:</strong>{' '}
                          {formatTimeAgo(domain.lastParsedAt)}
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer style={{ textAlign: 'right' }}>
                    {domain.twitterAuth ? (
                      <Button
                        size="sm"
                        variant="danger"
                        className="me-1"
                        onClick={() =>
                          handleRemoveTwitterPermissions(domain._id)
                        }
                      >
                        <FaTrash className="me-1" /> Remove Twitter Permissions
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        variant="primary"
                        className="me-1"
                        onClick={() => handleShowTwitterOAuthModal(domain)}
                      >
                        <FaPlus className="me-1" /> Connect Twitter
                      </Button>
                    )}
                    <Button
                      size="sm"
                      variant="info"
                      className="me-1"
                      onClick={() => handleShowEmbedModal(domain)}
                    >
                      <FaCode className="me-1" /> Embed Code
                    </Button>
                    <Button
                      size="sm"
                      variant="warning"
                      className="me-1"
                      onClick={() => handleShowModal(domain)}
                    >
                      <FaEdit className="me-1" /> Edit
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => handleDeleteItem(domain._id, 'domain')}
                    >
                      <FaTrash className="me-1" /> Delete
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            ))
          )}
        </Row>
        <Row className="pagination">
          <Col>
            <Button
              className="btn-falcon-primary me-1"
              onClick={() => handlePageChange(page - 1)}
              disabled={page <= 1}
            >
              Previous
            </Button>{' '}
            <Button
              className="btn-falcon-primary me-1"
              onClick={() => handlePageChange(page + 1)}
              disabled={page >= pages}
            >
              Next
            </Button>
          </Col>
          <Col className="text-end">
            <span>
              {' '}
              Page{' '}
              <strong>
                {page} of {pages}
              </strong>{' '}
            </span>
            <Form.Control
              as="select"
              value={limit}
              onChange={e => handlePageSizeChange(Number(e.target.value))}
              style={{ width: 'auto', display: 'inline-block' }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Card.Body>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? 'Edit Domain' : 'Create Domain'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Form>
            <Form.Group className="mb-3" controlId="formDomainUrl">
              <Form.Label>Domain URL</Form.Label>
              <Form.Control
                type="text"
                value={currentDomain.domainUrl}
                onChange={e =>
                  setCurrentDomain({
                    ...currentDomain,
                    domainUrl: e.target.value
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formHostPage">
              <Form.Label>Host Page</Form.Label>
              <Form.Control
                type="text"
                value={currentDomain.hostPage}
                onChange={e =>
                  setCurrentDomain({
                    ...currentDomain,
                    hostPage: e.target.value
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formApiEndpoint">
              <Form.Label>API Endpoint</Form.Label>
              <Form.Control
                type="text"
                value={currentDomain.apiEndpoint}
                onChange={e =>
                  setCurrentDomain({
                    ...currentDomain,
                    apiEndpoint: e.target.value
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFrequency">
              <Form.Label>Frequency</Form.Label>
              <Row>
                <Col xs={6}>
                  <Form.Control
                    type="number"
                    value={currentDomain.frequencyValue}
                    onChange={e =>
                      setCurrentDomain({
                        ...currentDomain,
                        frequencyValue: e.target.value
                      })
                    }
                  />
                </Col>
                <Col xs={6}>
                  <Form.Control
                    as="select"
                    value={currentDomain.frequencyUnit}
                    onChange={e =>
                      setCurrentDomain({
                        ...currentDomain,
                        frequencyUnit: e.target.value
                      })
                    }
                  >
                    <option value="s">Seconds</option>
                    <option value="t">Minutes</option>
                    <option value="h">Hours</option>
                    <option value="d">Days</option>
                    <option value="m">Months</option>
                    <option value="y">Years</option>
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPostParamsMapping">
              <Form.Label>Post Params Mapping</Form.Label>
              {Object.keys(initialPostParams).map(param => (
                <Form.Group
                  key={param}
                  className="mb-3"
                  controlId={`formPostParam${param}`}
                >
                  <Form.Label>{param}</Form.Label>
                  <Form.Control
                    type="text"
                    value={postParams[param]}
                    placeholder={`Enter the target field for ${param}`}
                    onChange={e =>
                      setPostParams({
                        ...postParams,
                        [param]: e.target.value
                      })
                    }
                  />
                </Form.Group>
              ))}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveDomain}
            disabled={status === 'loading'}
          >
            {status === 'loading' ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'Save Changes'
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAccountModal} onHide={handleCloseAccountModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formTwitterId">
              <Form.Label>Twitter ID</Form.Label>
              <Form.Control
                type="text"
                value={newAccount.twitterId}
                onChange={e =>
                  setNewAccount({ ...newAccount, twitterId: e.target.value })
                }
              />
            </Form.Group>
            {accountError && <Alert variant="danger">{accountError}</Alert>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAccountModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveAccount}
            disabled={accountLoading}
          >
            {accountLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'Add Account'
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWebsiteModal} onHide={handleCloseWebsiteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Website</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formWebsiteUrl">
              <Form.Label>Website URL</Form.Label>
              <Form.Control
                type="text"
                value={newWebsite.url}
                onChange={e =>
                  setNewWebsite({ ...newWebsite, url: e.target.value })
                }
              />
            </Form.Group>
            {websiteError && <Alert variant="danger">{websiteError}</Alert>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseWebsiteModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveWebsite}
            disabled={websiteLoading}
          >
            {websiteLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'Add Website'
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showKeywordModal} onHide={handleCloseKeywordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Keyword</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formKeywordPhrase">
              <Form.Label>Keyword Phrase</Form.Label>
              <Form.Control
                type="text"
                value={newKeyword.phrase}
                onChange={e =>
                  setNewKeyword({ ...newKeyword, phrase: e.target.value })
                }
              />
            </Form.Group>
            {keywordError && <Alert variant="danger">{keywordError}</Alert>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseKeywordModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveKeyword}
            disabled={keywordLoading}
          >
            {keywordLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'Add Keyword'
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCategoryModal} onHide={handleCloseCategoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formCategoryLabel">
              <Form.Label>Category Label</Form.Label>
              <Form.Control
                type="text"
                value={newCategory.label}
                onChange={e =>
                  setNewCategory({ ...newCategory, label: e.target.value })
                }
              />
            </Form.Group>
            {categoryError && <Alert variant="danger">{categoryError}</Alert>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCategoryModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveCategory}
            disabled={categoryLoading}
          >
            {categoryLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'Add Category'
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteItem}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEmbedModal} onHide={handleCloseEmbedModal}>
        <Modal.Header closeButton>
          <Modal.Title>Embed Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Set Layout</h5>
          <Form>
            <Form.Group className="mb-3" controlId="formCategoryLabel">
              <Form.Label>Avatar Visitbility</Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="Show"
                  name="avatar"
                  type="radio"
                  id={`inline-radio-1`}
                  checked={showAvatar}
                  onChange={() => setShowAvatar(true)}
                />
                <Form.Check
                  inline
                  label="Hide"
                  name="avatar"
                  type="radio"
                  id={`inline-radio-2`}
                  checked={!showAvatar}
                  onChange={() => setShowAvatar(false)}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCategoryLabel">
              <Form.Label>Columns</Form.Label>
              <Form.Select
                aria-label="Select columns"
                value={layoutColumn}
                onChange={event => setLayoutColumn(event.target.value)}
              >
                <option>Select columns</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Form.Select>
            </Form.Group>
          </Form>
          <hr />
          <h5>List View Embed Code</h5>
          <p>Insert this script tag in the head or before closing body tag:</p>
          <Alert variant="dark">
            <pre>
              {`<script src="${baseUrl}/assets/scripts/news-genie-v1.0.js" 
                data-user-id="${currentDomain?.userId?._id}" data-domain-id="${currentDomain._id}" 
                data-show-avatar="${showAvatar}" data-columns="${layoutColumn}"></script>`}
            </pre>
          </Alert>
          <p>Insert this div tag where you want to display the articles:</p>
          <Alert variant="dark">
            <pre>{`<div id="embed-newsgenie"></div>`}</pre>
          </Alert>
          <h5 className="mt-4">Full Page View Embed Code</h5>
          <p>Insert this script tag in the head or before closing body tag:</p>
          <Alert variant="dark">
            <pre>
              {`<script src="${baseUrl}/assets/scripts/news-genie-layout-v1.0.js" 
                data-user-id="${currentDomain?.userId?._id}" data-domain-id="${currentDomain._id}"
                data-show-avatar="${showAvatar}" data-columns="${layoutColumn}"></script>`}
            </pre>
          </Alert>
          <p>Insert this div tag where you want to display the content:</p>
          <Alert variant="dark">
            <pre>{`<div id="embed-newsgenie"></div>`}</pre>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEmbedModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTwitterOAuthModal} onHide={handleCloseTwitterOAuthModal}>
        <Modal.Header closeButton>
          <Modal.Title>Connect Twitter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Click this button to allow us to post articles on your twitter feed.
          </p>
          {selectedDomain && (
            <TwitterOAuth
              domainId={selectedDomain._id}
              onSuccess={() => {
                handleCloseTwitterOAuthModal();
                dispatch(getDomains({ page, limit, searchTerm }));
              }}
              onFailure={() => {
                handleCloseTwitterOAuthModal();
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTwitterOAuthModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default Domains;
