import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Col,
  Spinner,
  Badge,
  Button,
  Form,
  Row,
  ButtonGroup,
  CardHeader
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import { baseUrl } from 'helpers/requests';
import CustomPagination from 'components/utilities/CustomPagination';
import {
  selectArticleStatus,
  selectArticles,
  selectArticleError,
  getArticles
} from '../../redux/articleSlice';

// Optional label maps for friendlier display
const iterationLabelMap = {
  source: 'Source',
  iterationOne: 'Iteration One',
  iterationTwo: 'Iteration Two'
};

const modelLabelMap = {
  openai: 'OpenAI',
  anthropic: 'Anthropic',
  grok: 'Grok',
  gemini: 'Gemini',
  qwen: 'Qwen'
};

// Potential dropdown keys
const ALL_ITERATIONS = ['source', 'iterationOne', 'iterationTwo'];
const ALL_MODELS = ['openai', 'anthropic', 'grok', 'gemini', 'qwen'];

// Example transformation metrics
const TRANSFORMATION_METRICS = [
  'contentSimilarity',
  'biasAdjustment',
  'structuralChanges',
  'lexicalChanges',
  'weightedScore'
];

const biasTable = {
  'framing bias': { threshold: 0.33 },
  'tone bias': { threshold: 0.33 },
  sensationalism: { threshold: 0.23 },
  'false balance': { threshold: 0.17 },
  'omission bias': { threshold: 0.23 },
  'narrative bias': { threshold: 0.33 },
  'linguistic bias': { threshold: 0.27 },
  'text-level context bias': { threshold: 0.27 },
  'cognitive bias': { threshold: 0.33 },
  'hate speech': { threshold: 0.1 },
  'racial bias': { threshold: 0.1 },
  'fake news': { threshold: 0.1 },
  'gender bias': { threshold: 0.17 },
  'political bias': { threshold: 0.33 },
  'negative sentiment': { threshold: 0.33 },
  classism: { threshold: 0.17 },
  stereotyping: { threshold: 0.17 },
  racism: { threshold: 0.1 },
  ageism: { threshold: 0.17 },
  'religious bias': { threshold: 0.17 },
  'confirmation bias': { threshold: 0.27 },
  'recency bias': { threshold: 0.33 },
  'authority bias': { threshold: 0.27 },
  'bandwagon effect': { threshold: 0.27 },
  'selection bias': { threshold: 0.23 },
  'survivorship bias': { threshold: 0.23 },
  'availability bias': { threshold: 0.27 },
  'anchoring bias': { threshold: 0.27 },
  'geographic bias': { threshold: 0.27 },
  'temporal bias': { threshold: 0.33 },
  'rhetoric bias': { threshold: 0.2 },
  'statement bias': { threshold: 0.17 },
  'ideology bias': { threshold: 0.23 },
  argumentation: { threshold: 0.2 },
  blaming: { threshold: 0.13 },
  demonization: { threshold: 0.1 },
  emotions: { threshold: 0.23 },
  fabulation: { threshold: 0.1 },
  'fear-mongering': { threshold: 0.13 },
  labeling: { threshold: 0.17 },
  relativization: { threshold: 0.2 }
};

const BiasReport = () => {
  const dispatch = useDispatch();
  const articles = useSelector(selectArticles);
  const status = useSelector(selectArticleStatus);
  const error = useSelector(selectArticleError);
  const { pages } = useSelector(state => state.article);

  const [currentPage, setCurrentPage] = useState(1);
  const [hideZeroValues, setHideZeroValues] = useState(false);
  const [showSourceText, setShowSourceText] = useState(false);

  // iteration/model toggles for the chart
  const [selectedIterations, setSelectedIterations] = useState(ALL_ITERATIONS);
  const [selectedModels, setSelectedModels] = useState(ALL_MODELS);

  // iteration/model selection for the article text
  const [textDisplayOption, setTextDisplayOption] = useState('body');
  const [selectedTextModel, setSelectedTextModel] = useState('openai');
  const [selectedMetric, setSelectedMetric] = useState('contentSimilarity');

  useEffect(() => {
    dispatch(getArticles({ page: currentPage, limit: 1 }));
  }, [dispatch, currentPage]);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const formatDate = date => moment(date).format('Do MMMM');

  // Toggle iteration in chart
  const handleIterationToggle = iteration => {
    setSelectedIterations(prev =>
      prev.includes(iteration)
        ? prev.filter(i => i !== iteration)
        : [...prev, iteration]
    );
  };

  // Toggle model in chart
  const handleModelToggle = model => {
    setSelectedModels(prev =>
      prev.includes(model) ? prev.filter(m => m !== model) : [...prev, model]
    );
  };

  // -------------------------------------------------------
  // NEW: Export Handlers
  // -------------------------------------------------------
  const handleExportAsJSON = article => {
    const filename = `biasReport_${article._id}.json`;
    const jsonString = JSON.stringify(article, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();

    URL.revokeObjectURL(url);
  };

  const handleExportAsCSV = article => {
    const filename = `biasReport_${article._id}.csv`;
    const fields = Object.keys(article);
    let csvString = fields.join(',') + '\n';

    // Very basic CSV for top-level props
    const values = fields.map(key => {
      const val =
        article[key] !== undefined ? JSON.stringify(article[key]) : '';
      return val.replace(/"/g, '""');
    });
    csvString += values.join(',') + '\n';

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();

    URL.revokeObjectURL(url);
  };

  // -------------------------------------------------------
  // Chart logic
  // -------------------------------------------------------
  const getChartOptions = article => {
    if (!article || !article.biasData) return {};

    const seriesMap = {};
    const biasTypes = Object.keys(biasTable).sort();

    for (let iteration of selectedIterations) {
      const iterData = article.biasData[iteration];
      if (!iterData) continue;

      for (let model of selectedModels) {
        const modelData = iterData[model]?.bias_levels;
        if (!modelData) continue;

        const iLabel = iterationLabelMap[iteration] || iteration;
        const mLabel = modelLabelMap[model] || model;
        const seriesName = `${iLabel} - ${mLabel}`;

        // seriesMap[seriesName] = biasTypes.map(b => modelData[b] || 0);

        seriesMap[seriesName] = biasTypes.map(type => {
          const normalizedType = type.toLowerCase();
          const underscoredType = normalizedType.replace(/\s+/g, '_');
          return (
            modelData[type] ??
            modelData[normalizedType] ??
            modelData[underscoredType] ??
            0
          );
        });
      }
    }

    let filteredBiasTypes = [...biasTypes];
    if (hideZeroValues) {
      filteredBiasTypes = biasTypes.filter((type, idx) =>
        Object.values(seriesMap).some(dataArr => dataArr[idx] > 0)
      );
    }

    const finalSeriesData = Object.entries(seriesMap).map(
      ([seriesName, origData]) => {
        const newData = filteredBiasTypes.map(type => {
          const originalIndex = biasTypes.indexOf(type);
          return origData[originalIndex] || 0;
        });
        return { name: seriesName, data: newData };
      }
    );

    const thresholds = filteredBiasTypes.map(
      type => biasTable[type]?.threshold || 0
    );

    const colorPalette = [
      '#164863',
      '#603F26',
      '#427D9D',
      '#6C4E31',
      '#9BBEC8',
      '#FFDBB5',
      '#008080',
      '#FF6B6B',
      '#6D3AF9',
      '#BFD200'
    ];

    return {
      title: {
        text: 'Bias Score Comparison',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredBiasTypes,
        axisLabel: {
          interval: 0,
          rotate: 45
        }
      },
      yAxis: {
        type: 'value',
        name: 'Bias Score',
        min: 0,
        max: 1
      },
      legend: {
        data: [...finalSeriesData.map(s => s.name), 'Thresholds'],
        bottom: 0
      },
      series: [
        ...finalSeriesData.map((s, idx) => ({
          name: s.name,
          type: 'bar',
          data: s.data,
          itemStyle: {
            color: colorPalette[idx % colorPalette.length]
          }
        })),
        {
          name: 'Thresholds',
          type: 'line',
          data: thresholds,
          lineStyle: {
            type: 'dashed',
            color: '#AF1740'
          },
          symbol: 'circle',
          symbolSize: 6
        }
      ]
    };
  };

  // Force a re-render on toggles
  const chartKey = `${selectedIterations.join('-')}_${selectedModels.join(
    '-'
  )}_${hideZeroValues}`;

  // -------------------------------------------------------
  // Text & Transformation logic
  // -------------------------------------------------------
  const getDisplayedText = article => {
    if (textDisplayOption === 'body') {
      return article.body;
    }
    if (!article.textData) {
      return 'No textData found on this article.';
    }
    const iterationObj = article.textData[textDisplayOption];
    if (!iterationObj) return `No text for iteration: ${textDisplayOption}.`;

    const text = iterationObj[selectedTextModel];
    if (!text) {
      return `No text for model: ${selectedTextModel}.`;
    }
    return text;
  };

  const getTransformationValue = article => {
    if (textDisplayOption === 'body') return 0;
    if (!article.transformationData) return 0;

    const iterationObj = article.transformationData[textDisplayOption];
    if (!iterationObj) return 0;

    const modelObj = iterationObj[selectedTextModel];
    if (!modelObj) return 0;

    return modelObj[selectedMetric] || 0;
  };

  const getGaugeOption = (value, metric) => ({
    title: {
      text: `Transformation: ${metric}`,
      left: 'center'
    },
    tooltip: {
      formatter: '{a} <br/>{b} : {c}%'
    },
    series: [
      {
        name: 'Transformation',
        type: 'gauge',
        detail: { formatter: '{value}%' },
        data: [{ value, name: metric }],
        min: 0,
        max: 100
      }
    ]
  });

  return (
    <>
      {/* Pagination Card */}
      <Card className="mb-4">
        <Card.Body>
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <Button
                variant="outline-primary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage <= 1}
              >
                Previous
              </Button>
            </div>
            <div className="col-auto">
              <CustomPagination
                pages={pages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            </div>
            <div className="col-auto">
              <Button
                variant="outline-primary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= pages}
              >
                Next
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Main Chart & Toggles Card */}
      <Card className="mb-4">
        <Card.Body>
          {status === 'loading' ? (
            <div
              style={{ height: '480px' }}
              className="d-flex justify-content-center align-items-center"
            >
              <Spinner animation="border" />
            </div>
          ) : status === 'failed' ? (
            <div>Error: {error}</div>
          ) : (
            <>
              {/* Iteration & Model Toggles for the CHART */}
              <Row className="mb-3">
                <Col>
                  <strong>Iterations:</strong>
                  <div>
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Source"
                      checked={selectedIterations.includes('source')}
                      onChange={() => handleIterationToggle('source')}
                    />
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Iteration One"
                      checked={selectedIterations.includes('iterationOne')}
                      onChange={() => handleIterationToggle('iterationOne')}
                    />
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Iteration Two"
                      checked={selectedIterations.includes('iterationTwo')}
                      onChange={() => handleIterationToggle('iterationTwo')}
                    />
                  </div>
                </Col>
                <Col>
                  <strong>Models:</strong>
                  <div>
                    <Form.Check
                      inline
                      type="checkbox"
                      label="OpenAI"
                      checked={selectedModels.includes('openai')}
                      onChange={() => handleModelToggle('openai')}
                    />
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Anthropic"
                      checked={selectedModels.includes('anthropic')}
                      onChange={() => handleModelToggle('anthropic')}
                    />
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Grok"
                      checked={selectedModels.includes('grok')}
                      onChange={() => handleModelToggle('grok')}
                    />
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Gemini"
                      checked={selectedModels.includes('gemini')}
                      onChange={() => handleModelToggle('gemini')}
                    />
                    <Form.Check
                      inline
                      type="checkbox"
                      label="Qwen"
                      checked={selectedModels.includes('qwen')}
                      onChange={() => handleModelToggle('qwen')}
                    />
                  </div>
                </Col>
              </Row>
              <Form.Check
                type="checkbox"
                label="Hide Zero Values"
                checked={hideZeroValues}
                onChange={e => setHideZeroValues(e.target.checked)}
                className="mb-3"
              />

              {/* Render Chart if we have data */}
              {articles.length > 0 && articles[0].biasData ? (
                <Row>
                  <Col>
                    <ReactECharts
                      key={chartKey}
                      option={getChartOptions(articles[0])}
                      style={{ height: '400px', width: '100%' }}
                    />
                  </Col>
                </Row>
              ) : (
                <p>No bias data available for the current article.</p>
              )}
            </>
          )}
        </Card.Body>
      </Card>

      {articles.length > 0 && articles[0].allsides ? (
        <Card className="mb-4">
          <CardHeader>
            <h4>AllSides Report</h4>
          </CardHeader>
          <Card.Body>
            {articles[0].allsides.source || articles[0].allsides.target ? ( // Check for new format
              <Row>
                {/* NewsGenie AllSides (target) */}
                {articles[0].allsides.target &&
                  articles[0].allsides.target.rating &&
                  articles[0].allsides.target.rating.length > 0 && (
                    <Col>
                      <h5>NewsGenie AllSides</h5>
                      {articles[0].allsides.target.rating.map(
                        (rating, index) => (
                          <React.Fragment key={index}>
                            <p>
                              <strong>Rating:</strong> {rating.rating}
                            </p>
                            <p>
                              <strong>Explanation:</strong> {rating.explanation}
                            </p>
                          </React.Fragment>
                        )
                      )}
                      {articles[0].allsides.target.analysis &&
                        articles[0].allsides.target.analysis.length > 0 && (
                          <>
                            <h5>Analysis:</h5>
                            {articles[0].allsides.target.analysis.map(
                              (item, index) => (
                                <React.Fragment key={index}>
                                  <p>
                                    <strong>Bias Type:</strong> {item.biasType}
                                  </p>
                                  <p>
                                    <strong>Biased Text:</strong>{' '}
                                    {item.biasedText}
                                  </p>
                                  <p>
                                    <strong>Explanation:</strong>{' '}
                                    {item.explanation}
                                  </p>
                                </React.Fragment>
                              )
                            )}
                          </>
                        )}
                    </Col>
                  )}
                {/* Source AllSides (source) */}
                {articles[0].allsides.source &&
                  articles[0].allsides.source.rating &&
                  articles[0].allsides.source.rating.length > 0 && (
                    <Col>
                      <h5>Source AllSides</h5>
                      {articles[0].allsides.source.rating.map(
                        (rating, index) => (
                          <React.Fragment key={index}>
                            <p>
                              <strong>Rating:</strong> {rating.rating}
                            </p>
                            <p>
                              <strong>Explanation:</strong> {rating.explanation}
                            </p>
                          </React.Fragment>
                        )
                      )}

                      {articles[0].allsides.source.analysis &&
                        articles[0].allsides.source.analysis.length > 0 && (
                          <>
                            <h5>Analysis:</h5>
                            {articles[0].allsides.source.analysis.map(
                              (item, index) => (
                                <React.Fragment key={index}>
                                  <p>
                                    <strong>Bias Type:</strong> {item.biasType}
                                  </p>
                                  <p>
                                    <strong>Biased Text:</strong>{' '}
                                    {item.biasedText}
                                  </p>
                                  <p>
                                    <strong>Explanation:</strong>{' '}
                                    {item.explanation}
                                  </p>
                                </React.Fragment>
                              )
                            )}
                          </>
                        )}
                    </Col>
                  )}
              </Row>
            ) : (
              // Fallback to old format
              <Row>
                <Col>
                  <h5>Rating: {articles[0].allsides.rating[0].rating}</h5>
                  <strong>Explanation:</strong>
                  <p>{articles[0].allsides.rating[0].explanation}</p>

                  <h5>Analysis:</h5>
                  {articles[0].allsides.analysis ? (
                    articles[0].allsides.analysis.map(item => {
                      return (
                        <>
                          <strong>Bias Type: {item.biasType}</strong>
                          <p className="mb-0">
                            <strong>Biased Text:</strong> {item.biasedText}
                          </p>
                          <p>
                            <strong>Explanation:</strong> {item.explanation}
                          </p>
                        </>
                      );
                    })
                  ) : (
                    <p>No analysis available.</p>
                  )}
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      ) : null}

      {/* Article List / Source Text, etc. */}
      <Card className="mb-4">
        <Card.Body>
          {articles.map(article => (
            <Row className="mb-4" key={article._id}>
              {/* Top portion: image, date, categories, etc. */}
              <Col md={12} lg={12} className="d-flex mb-4">
                <Card style={{ width: '100%' }}>
                  {article.image && article.image !== '' ? (
                    <Card.Img
                      variant="top"
                      src={`${baseUrl}/images/` + article.image}
                      alt={article.imageFilename}
                    />
                  ) : (
                    <Card.Img
                      variant="top"
                      src={article.images[0]}
                      alt={article.imageFilename}
                    />
                  )}
                  <Card.Body>
                    <Badge bg="primary">{formatDate(article.createdAt)}</Badge>
                    <div className="mb-3 mt-2 text-small">
                      {article.categories.map((category, idx) => (
                        <span key={idx} className="me-2">
                          <Link to="#">{category}</Link>
                        </span>
                      ))}
                    </div>

                    {/* If it's a tweet */}
                    {article.isTweet && (
                      <>
                        <hr />
                        {/* Handle tweetAccounts first, then tweetAccount */}
                        {article.tweetAccounts?.length > 0 ? (
                          <p>
                            Sources:{' '}
                            {[
                              ...new Set(
                                article.tweetAccounts.map(
                                  account => account.twitterId
                                )
                              )
                            ].map((twitterId, index) => (
                              <span key={index}>
                                <Link
                                  target="_blank"
                                  to={`https://x.com/${twitterId}`}
                                >
                                  @{twitterId}
                                </Link>{' '}
                              </span>
                            ))}
                          </p>
                        ) : article.tweetAccount ? (
                          <p>
                            Source:{' '}
                            <Link
                              target="_blank"
                              to={`https://x.com/${article.tweetAccount.twitterId}`}
                            >
                              @{article.tweetAccount.twitterId}
                            </Link>
                          </p>
                        ) : null}

                        <strong>Original Tweet:</strong>
                        {/* Handle tweetIds first, then tweetId */}
                        {article.tweetIds?.length > 0 ? (
                          article.tweetIds.map((tweet, index) => (
                            <p key={index}>{tweet?.tweetJson?.text}</p>
                          ))
                        ) : article.tweetId?.tweetJson?.text ? (
                          <p>{article.tweetId.tweetJson.text}</p>
                        ) : (
                          <p>No tweet data available.</p>
                        )}
                      </>
                    )}

                    <hr />
                    {/* Show or hide "sourceText" vs. iteration text */}
                    <Form.Check
                      type="checkbox"
                      label="Show Source Text (overrides below dropdowns)"
                      checked={showSourceText}
                      onChange={e => setShowSourceText(e.target.checked)}
                      className="mb-3"
                    />
                  </Card.Body>
                </Card>
              </Col>

              {/* Bottom portion: body OR textData + transformation metrics */}
              <Col md={12} lg={12} className="d-flex mb-4">
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    {showSourceText ? (
                      <Card.Text>
                        <div>
                          {article.sourceTexts
                            ? article.sourceTexts
                            : article.sourceText}
                        </div>
                      </Card.Text>
                    ) : (
                      <>
                        <Row className="mb-2">
                          <Col sm={6} md={4}>
                            <Form.Label>Select Content to Display</Form.Label>
                            <Form.Select
                              size="sm"
                              value={textDisplayOption}
                              onChange={e =>
                                setTextDisplayOption(e.target.value)
                              }
                            >
                              <option value="body">Article Body</option>
                              <option value="iterationOne">
                                Iteration One
                              </option>
                              <option value="iterationTwo">
                                Iteration Two
                              </option>
                            </Form.Select>
                          </Col>

                          {textDisplayOption !== 'body' && (
                            <Col sm={6} md={4}>
                              <Form.Label>Select Model</Form.Label>
                              <Form.Select
                                size="sm"
                                value={selectedTextModel}
                                onChange={e =>
                                  setSelectedTextModel(e.target.value)
                                }
                              >
                                <option value="openai">OpenAI</option>
                                <option value="anthropic">Anthropic</option>
                                <option value="grok">Grok</option>
                                <option value="gemini">Gemini</option>
                                <option value="qwen">Qwen</option>
                              </Form.Select>
                            </Col>
                          )}
                        </Row>

                        {textDisplayOption !== 'body' && (
                          <>
                            <p className="mb-2">
                              <strong>Transformation Metrics:</strong>
                            </p>
                            <ButtonGroup className="mb-3" size="sm">
                              {TRANSFORMATION_METRICS.map(metric => (
                                <Button
                                  key={metric}
                                  variant={
                                    selectedMetric === metric
                                      ? 'primary'
                                      : 'outline-primary'
                                  }
                                  onClick={() => setSelectedMetric(metric)}
                                >
                                  {metric}
                                </Button>
                              ))}
                            </ButtonGroup>

                            <ReactECharts
                              option={getGaugeOption(
                                getTransformationValue(article),
                                selectedMetric
                              )}
                              style={{ height: '400px', width: '100%' }}
                            />
                            <hr />
                          </>
                        )}

                        <Card.Text>
                          {textDisplayOption === 'body' ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  article.iteration && article.model
                                    ? article.textData[article.iteration][
                                        article.model
                                      ]
                                    : article.body
                              }}
                            />
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: getDisplayedText(article)
                              }}
                            />
                          )}
                        </Card.Text>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>

              {/* NEW: Export Buttons */}
              <Col md={12}>
                <div className="d-flex justify-content-end mb-4">
                  <Button
                    variant="outline-secondary"
                    className="me-2"
                    onClick={() => handleExportAsJSON(article)}
                  >
                    Export as JSON
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={() => handleExportAsCSV(article)}
                  >
                    Export as CSV
                  </Button>
                </div>
              </Col>
            </Row>
          ))}
        </Card.Body>
      </Card>
    </>
  );
};

export default BiasReport;
