import axios from 'axios';
import { createBrowserHistory } from 'history';

// Create a history object
const history = createBrowserHistory();

axios.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      history.push('/login');
      localStorage.removeItem('access');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

let _baseUrl = '';
if (window.location.hostname === 'localhost') {
  _baseUrl = 'http://localhost:4000';
  // _baseUrl = 'https://portal.newsgenie.ai';
} else {
  _baseUrl = window.location.protocol + '//' + window.location.host;
}
export const baseUrl = _baseUrl;
const url = baseUrl + '/api';

const getToken = () => {
  let payload = localStorage.getItem('access');
  if (payload) {
    payload = JSON.parse(payload);
  }
  return payload ? payload.token : '';
};

const getHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json'
  }
});

// Auth APIs
export const register = async userData => {
  try {
    const response = await axios.post(`${url}/register`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error registering user');
  }
};

export const verifyEmail = async token => {
  try {
    const response = await axios.get(`${url}/verify-email/${token}`);
    return response.data;
  } catch (error) {
    throw new Error('Error verifying email');
  }
};

export const login = async userData => {
  try {
    const response = await axios.post(`${url}/login`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error logging in');
  }
};

export const loginWith2FA = async userData => {
  try {
    const response = await axios.post(`${url}/login/2fa`, userData);
    return response.data;
  } catch (error) {
    throw new Error('Error logging in with 2FA');
  }
};

export const fetchProtected = async () => {
  try {
    const response = await axios.get(`${url}/protected`, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error accessing protected route');
  }
};

export const forgotPassword = async email => {
  try {
    const response = await axios.post(`${url}/forgot-password`, { email });
    return response.data;
  } catch (error) {
    throw new Error('Error requesting password reset');
  }
};

export const resetPasswordGet = async token => {
  try {
    const response = await axios.get(`${url}/reset-password/${token}`);
    return response.data;
  } catch (error) {
    throw new Error('Error accessing password reset');
  }
};

export const resetPasswordPost = async (token, passwords) => {
  try {
    const response = await axios.post(
      `${url}/reset-password/${token}`,
      passwords
    );
    return response.data;
  } catch (error) {
    throw new Error('Error resetting password');
  }
};

export const setup2FA = async () => {
  try {
    const response = await axios.get(`${url}/setup-2fa`, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error setting up 2FA');
  }
};

export const verify2FA = async data => {
  try {
    const response = await axios.post(`${url}/setup-2fa`, data, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error verifying 2FA');
  }
};

// User APIs
export const fetchUsers = async () => {
  try {
    const response = await axios.get(`${url}/users`, getHeaders());
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching users');
  }
};

export const fetchUserById = async userId => {
  try {
    const response = await axios.get(`${url}/users/${userId}`, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error fetching user');
  }
};

export const createUser = async userData => {
  try {
    const response = await axios.post(`${url}/users`, userData, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error creating user');
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await axios.put(
      `${url}/users/${userId}`,
      userData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating user');
  }
};

export const deleteUser = async userId => {
  try {
    const response = await axios.delete(`${url}/users/${userId}`, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error deleting user');
  }
};

// Domain APIs
export const fetchDomains = async () => {
  try {
    const response = await axios.get(`${url}/domains`, getHeaders());
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching domains');
  }
};

export const fetchDomainById = async domainId => {
  try {
    const response = await axios.get(
      `${url}/domains/${domainId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error fetching domain');
  }
};

export const createDomain = async domainData => {
  try {
    const response = await axios.post(
      `${url}/domains`,
      domainData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error('Error creating domain');
  }
};

export const updateDomain = async (domainId, domainData) => {
  try {
    const response = await axios.put(
      `${url}/domains/${domainId}`,
      domainData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating domain');
  }
};

export const deleteDomain = async domainId => {
  try {
    const response = await axios.delete(
      `${url}/domains/${domainId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting domain');
  }
};

// Category APIs
export const fetchCategories = async domainId => {
  try {
    const response = await axios.get(
      `${url}/categories/${domainId}`,
      getHeaders()
    );
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching categories');
  }
};

export const createCategory = async categoryData => {
  try {
    const response = await axios.post(
      `${url}/categories`,
      categoryData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating category');
  }
};

export const updateCategory = async (categoryId, categoryData) => {
  try {
    const response = await axios.put(
      `${url}/categories/${categoryId}`,
      categoryData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating category');
  }
};

export const deleteCategory = async categoryId => {
  try {
    const response = await axios.delete(
      `${url}/categories/${categoryId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting category');
  }
};

// Update Category Order
export const updateCategoryOrder = async (domainId, categories) => {
  try {
    const response = await axios.put(
      `${url}/categories/order`,
      { categories },
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating category order');
  }
};

// Account APIs
export const createAccount = async accountData => {
  try {
    const response = await axios.post(
      `${url}/accounts`,
      accountData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating account');
  }
};

export const fetchAccounts = async domainId => {
  try {
    const response = await axios.get(
      `${url}/accounts/${domainId}`,
      getHeaders()
    );
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching accounts');
  }
};

export const fetchAccountById = async accountId => {
  try {
    const response = await axios.get(
      `${url}/accounts/${accountId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error fetching account');
  }
};

export const updateAccount = async (accountId, accountData) => {
  try {
    const response = await axios.put(
      `${url}/accounts/${accountId}`,
      accountData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating account');
  }
};

export const deleteAccount = async accountId => {
  try {
    const response = await axios.delete(
      `${url}/accounts/${accountId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting account');
  }
};

// Tweet APIs
export const fetchTweets = async data => {
  try {
    const response = await axios.get(`${url}/tweets`, {
      params: data,
      ...getHeaders()
    });
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching tweets');
  }
};

export const fetchTweetById = async tweetId => {
  try {
    const response = await axios.get(`${url}/tweets/${tweetId}`, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error fetching tweet');
  }
};

export const createTweet = async tweetData => {
  try {
    const response = await axios.post(`${url}/tweets`, tweetData, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error creating tweet');
  }
};

export const updateTweet = async (tweetId, tweetData) => {
  try {
    const response = await axios.put(
      `${url}/tweets/${tweetId}`,
      tweetData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating tweet');
  }
};

export const deleteTweet = async tweetId => {
  try {
    const response = await axios.delete(
      `${url}/tweets/${tweetId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting tweet');
  }
};

// Article APIs
export const fetchArticles = async data => {
  try {
    const response = await axios.get(`${url}/articles`, {
      params: data,
      ...getHeaders()
    });
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching articles');
  }
};

export const fetchArticleById = async articleId => {
  try {
    const response = await axios.get(
      `${url}/articles/${articleId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error fetching article');
  }
};

export const createArticle = async articleData => {
  try {
    const response = await axios.post(
      `${url}/articles`,
      articleData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating article');
  }
};

export const updateArticle = async (articleId, articleData) => {
  try {
    const response = await axios.put(
      `${url}/articles/${articleId}`,
      articleData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating article');
  }
};

export const deleteArticle = async articleId => {
  try {
    const response = await axios.delete(
      `${url}/articles/${articleId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting article');
  }
};

// Website APIs
export const createWebsite = async websiteData => {
  try {
    const response = await axios.post(
      `${url}/websites`,
      websiteData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating website');
  }
};

export const deleteWebsite = async websiteId => {
  try {
    const response = await axios.delete(
      `${url}/websites/${websiteId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting website');
  }
};

// Keyword APIs
export const createKeyword = async keywordData => {
  try {
    const response = await axios.post(
      `${url}/keywords`,
      keywordData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating keyword');
  }
};

export const deleteKeyword = async keywordId => {
  try {
    const response = await axios.delete(
      `${url}/keywords/${keywordId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting keyword');
  }
};

// Post APIs
export const fetchPosts = async data => {
  try {
    const response = await axios.get(`${url}/posts`, {
      params: data,
      ...getHeaders()
    });
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching posts');
  }
};

export const fetchPostById = async postId => {
  try {
    const response = await axios.get(`${url}/posts/${postId}`, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error fetching post');
  }
};

export const createPost = async postData => {
  try {
    const response = await axios.post(`${url}/posts`, postData, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error creating post');
  }
};

export const updatePost = async (postId, postData) => {
  try {
    const response = await axios.put(
      `${url}/posts/${postId}`,
      postData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating post');
  }
};

export const deletePost = async postId => {
  try {
    const response = await axios.delete(`${url}/posts/${postId}`, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error deleting post');
  }
};

// Worker State APIs
export const fetchWorkerStates = async data => {
  try {
    const response = await axios.get(`${url}/worker-states`, {
      params: data,
      ...getHeaders()
    });
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching worker states');
  }
};

export const fetchWorkerStateById = async workerStateId => {
  try {
    const response = await axios.get(
      `${url}/worker-states/${workerStateId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error fetching worker state');
  }
};

export const createWorkerState = async workerStateData => {
  try {
    const response = await axios.post(
      `${url}/worker-states`,
      workerStateData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating worker state');
  }
};

export const updateWorkerState = async (workerStateId, workerStateData) => {
  try {
    const response = await axios.put(
      `${url}/worker-states/${workerStateId}`,
      workerStateData,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating worker state');
  }
};

export const deleteWorkerState = async workerStateId => {
  try {
    const response = await axios.delete(
      `${url}/worker-states/${workerStateId}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting worker state');
  }
};

// Twitter Auth and Content Management
export const getTwitterAuthLink = async domainId => {
  const response = await axios.get(`${url}/twitter/auth-link`, {
    params: { domainId },
    withCredentials: true,
    ...getHeaders()
  });
  return response.data.data;
};

export const postArticleTweet = async tweetData => {
  const response = await axios.post(
    `${url}/twitter/tweet`,
    tweetData,
    getHeaders()
  );
  return response.data.data;
};

export const deleteArticleTweet = async (tweetId, domainId) => {
  const response = await axios.delete(`${url}/twitter/tweet/${tweetId}`, {
    data: { domainId },
    ...getHeaders()
  });
  return response.data.data;
};

// BiasChecker APIs
export const fetchBiasCheckers = async data => {
  try {
    const response = await axios.get(`${url}/bias-checker`, {
      params: data,
      ...getHeaders()
    });
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching biasCheckers');
  }
};

export const fetchBiasCheckerById = async id => {
  try {
    const response = await axios.get(`${url}/bias-checker/${id}`, getHeaders());
    return response.data;
  } catch (error) {
    throw new Error('Error fetching biasChecker');
  }
};

export const createBiasChecker = async data => {
  try {
    const response = await axios.post(
      `${url}/bias-checker`,
      data,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating biasChecker');
  }
};

export const updateBiasChecker = async (id, data) => {
  try {
    const response = await axios.put(
      `${url}/bias-checker/${id}`,
      data,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating biasChecker');
  }
};

export const deleteBiasChecker = async id => {
  try {
    const response = await axios.delete(
      `${url}/bias-checker/${id}`,
      getHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting biasChecker');
  }
};
