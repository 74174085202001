import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Spinner,
  Form,
  Modal,
  Badge
} from 'react-bootstrap';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender
} from '@tanstack/react-table';
import { deleteArticle } from 'helpers/requests';
import { FaTrash, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { getArticles } from '../../redux/articleSlice';
import moment from 'moment';

const Articles = () => {
  const dispatch = useDispatch();
  const articles = useSelector(state => state.article.articles);
  const status = useSelector(state => state.article.status);
  const {
    page: currentPage,
    pages,
    limit,
    total
  } = useSelector(state => state.article);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteArticleId, setDeleteArticleId] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      dispatch(getArticles({ page: 1, limit }));
      setInitialized(true);
    }
  }, [dispatch, limit, initialized]);

  const columns = [
    {
      accessorKey: 'title',
      header: 'Title',
      cell: ({ row }) => (
        <>
          <div className="d-flex align-items-center">
            <div>
              <div>
                <Badge bg="success" className="me-1">
                  {row.original.userId.username}
                </Badge>
                <Badge bg="info" className="me-1">
                  {new URL(row.original.domainId.domainUrl).hostname || null}
                </Badge>
              </div>
              {row.original.title}
              <div>
                {row.original.categories.map((category, index) => (
                  <Badge key={index} bg="secondary" className="me-1">
                    {category}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      accessorKey: 'retries',
      header: 'Retries'
    },
    {
      accessorKey: 'isPosted',
      header: 'Status',
      cell: ({ row }) => (
        <Badge bg={row.original.isPosted ? 'success' : 'warning'}>
          {row.original.isPosted ? 'Posted' : 'Not Posted'}
        </Badge>
      )
    },
    {
      accessorKey: 'location',
      header: 'Location'
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      cell: ({ row }) => {
        const now = moment();
        const createdAt = moment(row.original.createdAt);
        const duration = moment.duration(now.diff(createdAt));

        let timeAgo;
        if (duration.asMinutes() < 60) {
          const minutes = Math.floor(duration.asMinutes());
          timeAgo = minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
        } else if (duration.asHours() < 24) {
          const hours = Math.floor(duration.asHours());
          timeAgo = hours === 1 ? '1 hour ago' : `${hours} hours ago`;
        } else if (duration.asDays() < 7) {
          const days = Math.floor(duration.asDays());
          timeAgo = days === 1 ? '1 day ago' : `${days} days ago`;
        } else if (duration.asWeeks() < 4) {
          const weeks = Math.floor(duration.asWeeks());
          timeAgo = weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
        } else if (duration.asMonths() < 12) {
          const months = Math.floor(duration.asMonths());
          timeAgo = months === 1 ? '1 month ago' : `${months} months ago`;
        } else {
          const years = Math.floor(duration.asYears());
          timeAgo = years === 1 ? '1 year ago' : `${years} years ago`;
        }

        return <>{timeAgo}</>;
      }
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <div className="text-end">
          <FaTrash
            className="text-danger cursor-pointer"
            onClick={() => handleDeleteArticle(row.original._id)}
          />
        </div>
      )
    }
  ];

  const table = useReactTable({
    data: articles,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: limit,
        pageIndex: 0
      }
    },
    manualPagination: true,
    pageCount: pages,
    state: {
      pagination: {
        pageIndex: currentPage - 1,
        pageSize: limit
      }
    }
  });

  const { getHeaderGroups, getRowModel, setGlobalFilter } = table;

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(getArticles({ page: currentPage - 1, limit }));
    }
  };

  const handleNextPage = () => {
    if (currentPage < pages) {
      dispatch(getArticles({ page: parseInt(currentPage) + 1, limit }));
    }
  };

  const handlePageSizeChange = e => {
    const newSize = Number(e.target.value);
    dispatch(getArticles({ page: 1, limit: newSize }));
  };

  const handleDeleteArticle = articleId => {
    setDeleteArticleId(articleId);
    setShowDeleteModal(true);
  };

  const confirmDeleteArticle = async () => {
    try {
      await deleteArticle(deleteArticleId);
      dispatch(getArticles({ page: currentPage, limit }));
      setShowDeleteModal(false);
      setDeleteArticleId(null);
    } catch (error) {
      console.error('Error deleting article:', error);
    }
  };

  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between mt-4">
          <Col sm={12} md={2}>
            <h3 className="text-primary">Articles</h3>
          </Col>
          <Col sm={8} md={7}>
            <Form.Control
              type="text"
              placeholder="Search..."
              onChange={e => setGlobalFilter(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {status === 'loading' ? (
              <Spinner animation="border" />
            ) : (
              <Table responsive>
                <thead>
                  {getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {getRowModel().rows.map(row => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Row className="pagination">
              <Col>
                <Button
                  className="btn-falcon-primary me-1"
                  onClick={handlePreviousPage}
                  disabled={currentPage <= 1}
                >
                  <FaChevronLeft color="black" />
                </Button>
                <Button
                  className="btn-falcon-primary me-1"
                  onClick={handleNextPage}
                  disabled={currentPage >= pages}
                >
                  <FaChevronRight color="black" />
                </Button>
              </Col>
              <Col className="text-end">
                <span>
                  Page{' '}
                  <strong>
                    {currentPage} of {pages}
                  </strong>{' '}
                </span>
                <Form.Control
                  as="select"
                  value={limit}
                  onChange={handlePageSizeChange}
                  style={{ width: 'auto', display: 'inline-block' }}
                >
                  {[10, 20, 30, 40, 50].map(size => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-end">
                <span>
                  Showing {(currentPage - 1) * limit + 1} to{' '}
                  {Math.min(currentPage * limit, total)} of {total} entries
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this article?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteArticle}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default Articles;
