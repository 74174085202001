import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Table, Spinner } from 'react-bootstrap';
import { getWorkerStates, editWorkerState } from '../../redux/workerStateSlice';
import { FaToggleOn } from 'react-icons/fa';

const WorkerStates = () => {
  const dispatch = useDispatch();
  const workerStates = useSelector(state => state.workerState.workerStates);
  const status = useSelector(state => state.workerState.status);
  const { page, pages, limit } = useSelector(state => state.workerState);

  useEffect(() => {
    dispatch(getWorkerStates({ page, limit }));
  }, [dispatch, page, limit]);

  const toggleWorkerState = async (workerStateId, field, value) => {
    try {
      const updatedWorkerState = { [field]: value };
      await dispatch(
        editWorkerState({ workerStateId, workerStateData: updatedWorkerState })
      );
      dispatch(getWorkerStates({ page, limit }));
    } catch (error) {
      console.error('Error updating worker state:', error);
    }
  };

  const columns = [
    {
      accessorKey: 'parseTweets',
      header: 'Parse Tweets',
      cell: ({ row }) => (
        <FaToggleOn
          className={
            row.original.parseTweets ? 'text-success' : 'text-secondary'
          }
          style={{ cursor: 'pointer', fontSize: '1.5rem' }}
          onClick={() =>
            toggleWorkerState(
              row.original._id,
              'parseTweets',
              !row.original.parseTweets
            )
          }
        />
      )
    },
    {
      accessorKey: 'parseWebsite',
      header: 'Parse Website',
      cell: ({ row }) => (
        <FaToggleOn
          className={
            row.original.parseWebsite ? 'text-success' : 'text-secondary'
          }
          style={{ cursor: 'pointer', fontSize: '1.5rem' }}
          onClick={() =>
            toggleWorkerState(
              row.original._id,
              'parseWebsite',
              !row.original.parseWebsite
            )
          }
        />
      )
    },
    {
      accessorKey: 'processPosts',
      header: 'Process Posts',
      cell: ({ row }) => (
        <FaToggleOn
          className={
            row.original.processPosts ? 'text-success' : 'text-secondary'
          }
          style={{ cursor: 'pointer', fontSize: '1.5rem' }}
          onClick={() =>
            toggleWorkerState(
              row.original._id,
              'processPosts',
              !row.original.processPosts
            )
          }
        />
      )
    },
    {
      accessorKey: 'processTweets',
      header: 'Process Tweets',
      cell: ({ row }) => (
        <FaToggleOn
          className={
            row.original.processTweets ? 'text-success' : 'text-secondary'
          }
          style={{ cursor: 'pointer', fontSize: '1.5rem' }}
          onClick={() =>
            toggleWorkerState(
              row.original._id,
              'processTweets',
              !row.original.processTweets
            )
          }
        />
      )
    },
    {
      accessorKey: 'processAvatar',
      header: 'Process Avatar',
      cell: ({ row }) => (
        <FaToggleOn
          className={
            row.original.processAvatar ? 'text-success' : 'text-secondary'
          }
          style={{ cursor: 'pointer', fontSize: '1.5rem' }}
          onClick={() =>
            toggleWorkerState(
              row.original._id,
              'processAvatar',
              !row.original.processAvatar
            )
          }
        />
      )
    }
  ];

  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between mt-4">
          <Col sm={12} md={2}>
            <h3 className="text-primary">Worker States</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {status === 'loading' ? (
              <Spinner animation="border" />
            ) : workerStates.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    {columns.map(column => (
                      <th key={column.accessorKey}>{column.header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {workerStates.map(workerState => (
                    <tr key={workerState._id}>
                      {columns.map(column => (
                        <td key={`${workerState._id}-${column.accessorKey}`}>
                          {column.cell({ row: { original: workerState } })}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No worker states found</p>
            )}
            {pages > 1 && (
              <Row className="pagination">
                <Col>
                  <Button
                    className="btn-falcon-primary me-1"
                    onClick={() =>
                      dispatch(getWorkerStates({ page: page - 1, limit }))
                    }
                    disabled={page <= 1}
                  >
                    Previous
                  </Button>
                  <Button
                    className="btn-falcon-primary me-1"
                    onClick={() =>
                      dispatch(getWorkerStates({ page: page + 1, limit }))
                    }
                    disabled={page >= pages}
                  >
                    Next
                  </Button>
                </Col>
                <Col className="text-end">
                  <span>
                    Page <strong>{page}</strong> of <strong>{pages}</strong>
                  </span>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default WorkerStates;
