// components/TwitterOAuth.js
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTwitterAuthLink } from '../../redux/twitterAuthSlice';

const TwitterOAuth = ({ domainId }) => {
  const dispatch = useDispatch();
  const authUrl = useSelector(state => state.twitterAuth.authUrl);

  useEffect(() => {
    if (domainId) {
      dispatch(fetchTwitterAuthLink(domainId));
    }
  }, [dispatch, domainId]);

  const handleAuth = () => {
    window.location.href = authUrl;
  };

  return (
    <div>
      <button className="btn btn-primary" onClick={handleAuth}>
        Connect to Twitter
      </button>
    </div>
  );
};

TwitterOAuth.propTypes = {
  domainId: PropTypes.string.isRequired
};

export default TwitterOAuth;
